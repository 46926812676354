<template>
    <v-progress-circular
        style="margin-left: 10px"
        :width="3"
        :color="cor"
        indeterminate
    ></v-progress-circular>
</template>
<style>

</style>
<script>
export default {
props: {
    cor: String
},
data: () => ({
dialog: true,

}),
};
</script>